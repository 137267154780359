import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Cookie from 'js-cookie';
import { isBrowser } from '../utils/ssr';

const getBrowserAndVersion = () => {
	let userAgent = window.navigator.userAgent;
	let browser = 'N/A';
	let browserVersion = 'N/A';

	if (window.navigator.userAgentData) {
		// Используем User-Agent Client Hints, если доступен
		browser = window.navigator.userAgentData.brands[0]?.brand || 'N/A';
		browserVersion = window.navigator.userAgentData.brands[0]?.version || 'N/A';
	} else {
		// Фоллбэк для браузеров, не поддерживающих User-Agent Client Hints
		let match = userAgent.match(/(firefox|msie|trident|chrome|safari|opr|edg|brave|steam)\/?\s*(\d+)/i) || [];
		let name = match[1] || '';
		let version = match[2] || '0';

		switch (name.toLowerCase()) {
			case 'firefox':
				browser = 'Firefox';
				browserVersion = version;
				break;
			case 'msie':
			case 'trident':
				browser = 'Internet Explorer';
				browserVersion = version;
				break;
			case 'chrome':
				browser = 'Chrome';
				browserVersion = version;
				break;
			case 'safari':
				browser = 'Safari';
				browserVersion = version;
				break;
			case 'opr':
				browser = 'Opera';
				browserVersion = version;
				break;
			case 'edg':
				browser = 'Edge';
				browserVersion = version;
				break;
			case 'brave':
				browser = 'Brave';
				browserVersion = version;
				break;
			case 'steam':
				browser = 'Steam';
				browserVersion = userAgent.match(/chrome\/(\d+)/i) ? RegExp.$1 : version;
				break;
			default:
				browser = 'N/A';
				browserVersion = 'N/A';
				break;
		}
	}

	if (browser === 'N/A') {
		if (
			userAgent.includes('Valve') ||
			userAgent.includes('valve') ||
			userAgent.includes('Steam') ||
			userAgent.includes('steam')
		) {
			browser = 'Steam Browser';
		}
	}

	return { browser, browserVersion };
};

class MixPanelService {
	constructor() {
		this.instance = axios.create({
			baseURL: process.env.GATSBY_API_URL.slice(0, -3),
			timeout: 60000,
			headers: {
				credentials: 'same-origin',
				'content-type': 'application/json',
			},
			withCredentials: true,
		});

		this.id = null;

		if (Cookie.get('mix-panel-uuid')) {
			this.id = Cookie.get('mix-panel-uuid');
		} else {
			this.id = uuidv4();
			if (isBrowser) {
				Cookie.set('mix-panel-uuid', this.id, {
					domain: `.${window.location.host}`,
					expires: 365,
				});
			}
		}
	}

	getOneTimeParams() {
		if (Cookie.get('mix-panel-one-time-params')) {
			return JSON.parse(Cookie.get('mix-panel-one-time-params'));
		}

		const oneTimeParams = {
			OS: window.navigator?.platform || 'N/A',
			Browser: getBrowserAndVersion().browser || 'N/A',
			BrowserVersion: getBrowserAndVersion().browserVersion || 'N/A',
			ScreenHeight: window.screen.height || 'N/A',
			ScreenWidth: window.screen.width || 'N/A',
			DeviceFamily: window.navigator?.userAgentData?.mobile ? 'Phone' : 'PC' || 'N/A',
			Referrer: document.referrer || 'N/A',
			ReferringDomain:
				document?.referrer && document?.referrer !== 'N/A' ? new URL(document.referrer).hostname || 'N/A' : 'N/A',
		};

		Cookie.set('mix-panel-one-time-params', JSON.stringify(oneTimeParams), { domain: `.${window.location.host}` });

		return oneTimeParams;
	}

	sendEvent(data, steamAccountId = null) {
		const url = new URL(window.location.href);
		const utmObject = {};
		const utmInitialSourceCookie = Cookie.get('initial-utm-source');
		const utmInitialCampaignCookie = Cookie.get('initial-utm-campaign');
		const utmInitialContentCookie = Cookie.get('initial-utm-content');
		const utmInitialMediumCookie = Cookie.get('initial-utm-medium');

		if (!utmInitialSourceCookie) {
			utmObject.initial_utm_source = url.searchParams.get('utm_source') || '-';
			Cookie.set('initial-utm-source', true);
		}

		if (!utmInitialCampaignCookie) {
			utmObject.initial_utm_campaign = url.searchParams.get('utm_campaign') || '-';
			Cookie.set('initial-utm-campaign', true);
		}

		if (!utmInitialContentCookie) {
			utmObject.initial_utm_content = url.searchParams.get('utm_content') || '-';
			Cookie.set('initial-utm-content', true);
		}

		if (!utmInitialMediumCookie) {
			utmObject.initial_utm_medium = url.searchParams.get('utm_medium') || '-';
			Cookie.set('initial-utm-medium', true);
		}

		if (url.searchParams.has('utm_source')) {
			utmObject.utm_source = url.searchParams.get('utm_source');
		}
		if (url.searchParams.has('utm_campaign')) {
			utmObject.utm_campaign = url.searchParams.get('utm_campaign');
		}
		if (url.searchParams.has('utm_content')) {
			utmObject.utm_content = url.searchParams.get('utm_content');
		}
		if (url.searchParams.has('utm_medium')) {
			utmObject.utm_medium = url.searchParams.get('utm_medium');
		}

		if (!data.properties) {
			data.properties = {};
		}

		Object.assign(data.properties, utmObject);

		if (process.browser) {
			const oneTimeParams = this.getOneTimeParams();
			Object.assign(data.properties, oneTimeParams);
		}

		if (steamAccountId) {
			return this.instance.post('/analytics/events', {
				...data,
				steamAccountId: steamAccountId,
			});
		} else {
			return this.instance.post('/analytics/events', {
				...data,
				temporaryId: this.id,
			});
		}
	}

	setProperty(profileOperations, steamAccountId = null) {
		if (steamAccountId) {
			return this.instance.post('/analytics/identity', {
				steamAccountId: steamAccountId,
				Operations: profileOperations,
			});
		}
	}
}

export const mixPanelService = new MixPanelService();
